<template>
  <div id="updateContent">
    <p id="notice">{{text}}</p>
  </div>
</template>
<script>
export default {
  data () {
    return {
      text: 'Checking the Upgrade File...'
    }
  },
  mounted () {
    if (window.iBrowser) {
      const plugin = this.$plugin
      // plugin.el.System_Command('mount -o iocharset=utf8 /dev/sda1 /mnt')
      setTimeout(() => {
        var fileExists = plugin.el.FileExists('/mnt/SigmastarUpgrade.bin')
        console.log('FileExists:' + fileExists)
        if (fileExists) {
          this.text = 'Updating...'
          plugin.el.System_Command('/etc/fw_setenv usbautoupgrade 1')
          setTimeout(() => {
            plugin.el.System_Command('reboot')
          }, 200)
        } else {
          this.text = 'No upgrade file'
          setTimeout(() => {
            this.back()
          }, 3000)
        }
      }, 200)
    } else {
      setTimeout(() => {
        this.back()
      }, 3000)
    }
  },
  methods: {
    back () {
      this.$router.replace('/setting')
    }
  }
}
</script>
<style lang="scss" scoped>
#updateContent {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 14;
  #notice {
    width: 100%;
    line-height: 60px;
    font-size: 30px;
    text-align: center;
    position: absolute;
    top: 200px;
    color: #fff;
  }
}
</style>
